import React from 'react';
import Layout from '../components/Layout';

import { articleStyle } from '../components/Layout/styles';

class MissingPage extends React.Component {
  render() {
    return (
      <Layout>
        <article css={articleStyle}>
          <h1>Oops!!!</h1>
          We could not find find the page you were looking for.
        </article>
      </Layout>
    );
  }
}

export default MissingPage;
